import React from 'react';
import { graphql } from 'gatsby';
import ClientCasePage from 'components/pages/ClientCasePage';

const seo = {
  title: 'Q°emotion - Use Cases',
  keywords: [
    'Case study',
    'Customer testimonial',
    'ROI',
    'SaaS solution',
    'Emotions analysis',
    'AI',
    'Customer reviews',
    'Q°emotion tool',
    'Conversational data',
    'Reduction of churn rate',
  ],
  description:
    'Understanding how the Q°emotion tool works through case studies',
};

export default props => <ClientCasePage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    allData: allMarkdownRemark(
      filter: {
        frontmatter: { pageType: { eq: "clientCase" }, lang: { eq: "en" } }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      group(field: frontmatter___useCases) {
        nodes {
          rawMarkdownBody
          frontmatter {
            title
            blockType
            useCases
            subTitle
            component
            relatedImageLeft
            relatedImageRight
            infos
          }
        }
      }
    }
    allImages: allFile(filter: { relativeDirectory: { eq: "bubbleImages" } }) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    dataBlockIntroTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "clientCase" }
        blockType: { eq: "introBlock" }
        component: { eq: "title" }
        lang: { eq: "en" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockIntroBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "introBlock" }
          component: { eq: "chips" }
          lang: { eq: "en" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        frontmatter {
          useCases
          active
        }
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "en" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
    dataTestimonialClientCaseBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "testimonialBlock" }
          lang: { eq: "en" }
          useCases: {
            in: [
              "heineken_experience"
              "transportation"
              "floa_bank"
              "richemont"
            ]
          }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          author
          role
          image
          youtubeID
          youtubeTitle
          useCases
          imageName
        }
      }
    }
    imagesTestimonialClientCaseBlock: allFile(
      filter: {
        relativeDirectory: {
          eq: "solutionPage/analyseVerbatim/testimonialBlock"
        }
        name: {
          in: ["corsair", "floa_bank", "heineken_experience", "richemont"]
        }
      }
    ) {
      nodes {
        base
        name
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`;
